import { Button } from 'base';
import React, { FC, useMemo } from 'react';

import styled from 'styled-components'


import { Modal } from '../../components/Modal/Modal';
import { ModalContent, ModalHeader, ModalFooter, ModalSection, ModalTitle } from '../../components/Modal/Modal.styles';
import { InfoModalProps } from '../../types';

const InfoModal: FC<InfoModalProps> = ({ id, onClose, buttons, type, content, subtitle, title, width }) => {
    const handleSubmit = () => {
        onClose && onClose();
    };

    const iconProps = useMemo(() => {
        switch (type) {
            case 'warning':
                return { icon: 'exclamation-triangle', color: '#f1c40f' }
            case 'error':
                return { icon: 'bug', color: '#e74c3c' }
            case 'success':
                return { icon: 'check', color: '#2ecc71' }
            case 'info':
                return { icon: 'info', color: '#3498db' }
            case 'none':
            default:
                return undefined
        }
    }, [type])

    return (
        <Modal id={id} onClose={onClose} resizable={false} width={width}>
            <ModalContent>
                <ModalHeader>
                    <ModalTitle>{title}</ModalTitle>
                </ModalHeader>
                <ModalSection mode="row">
                    <ContentWrapper>
                        {subtitle ? <strong style={{ marginBottom: 16 }}>{subtitle}</strong> : null}
                        {typeof content !== 'string' ? content : content}
                    </ContentWrapper>
                </ModalSection>
                <ModalFooter>
                        <Button autoFocus={true} type="primary" onClick={handleSubmit}>
                            {buttons.submit}
                        </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

InfoModal.defaultProps = {
    title: 'info-modal-title',
    content: 'info-modal-content',
    width: 450,
    buttons: {
        submit: 'button-ok-label',
    },
};

const IconWrapper = styled.div`
    width: 80px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;
    padding-right: 24px;
`

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    white-space: pre-wrap;
`

export default InfoModal;
