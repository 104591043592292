import React, { FC, useRef, useCallback, useEffect, useMemo, useState } from "react";

import ReactDOM from "react-dom";
import "react-resizable/css/styles.css";

import ModalActions from "../ModalActions/ModalActions";
import { ModalWrapper, ModalContainer, ModalOverlay } from "./Modal.styles";

import { ModalProps } from "../../types";

export const Modal: FC<ModalProps> = ({ onClose, className, closable = true, width, height, style = {}, children }) => {
  const modalContainerRef = useRef<HTMLDivElement>();

  const handleClose = useCallback(() => {
    return Promise.resolve(onClose());
  }, []);

  // @ts-ignore
  return ReactDOM.createPortal(
    <ModalWrapper id="modal-iclx" className={className}>
      <ModalOverlay id="overlay" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} />
      <ModalContainer id="modalContainer" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} ref={modalContainerRef}>
        <div className={`modal-resize-body`}>
          <ModalActions closable={closable} onClose={handleClose} />
          {children}
        </div>
      </ModalContainer>
    </ModalWrapper>,
    document.querySelector("body")
  );
};
