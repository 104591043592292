import { createAction } from '@reduxjs/toolkit'
import { createAction as reduxAction } from 'redux-actions'
import { Poster } from './types'
import * as POSTERAPI from './api'

export const updatePoster = createAction<{ poster: Poster }>('POSTER/UPDATE')

export const setIsSelectVariants = createAction<{ isSelectVariants: boolean }>('NAVBAR/UPDATE')

export const setPosterLoading = createAction<{ isLoading: boolean }>('POSTER/LOADING')

export const setCheckoutLoading = createAction<{ isLoading: boolean }>('CHECKOUT/LOADING')

export const getAudioFile = reduxAction('GET/AUDIO_FILE', (id) => {
  return POSTERAPI.getAudioFile(id)
})

export const getPosterFile = reduxAction('GET/POSTER_FILE', (id) => {
  return POSTERAPI.getPosterFile(id)
})

export const generatePosterPdf = reduxAction('SAVE/AUDIO_FILE', ({ id, audio, poster }) => {
  return POSTERAPI.generatePosterPdf({ id, audio, poster })
})
