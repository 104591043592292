import styled from 'styled-components'
import React, { useState } from 'react'
import PinInputGrid from './PinInputGrid'
import { useField } from 'react-final-form'

const PinInput: React.FC<PinInputProps> = ({
  pinLength,
  label,
  isTouched,
  hasError,
  showError,
  errorMessage,
  value = '',
  error,
  onChange
}) => {
  const [pin, setPin] = useState<Array<string | undefined>>(
    value.substring(0, pinLength).split('')
  )

  const onPinChanged = (pinEntry: number, index: number) => {
    const newPin = [...pin]
    //@ts-ignore
    newPin[index] = pinEntry
    setPin(newPin)
    onChange(newPin.join(''))
  }

  const onPaste = () => {
    navigator.clipboard
      .readText()
      .then((value) => {
        return value.replace(/[e\+\-]/gi, '')
      })
      .then((value) => {
        return value.substring(0, pinLength).split('')
      })
      .then((value) => {
        setPin(value)
        onChange(value.join(''))
      })
  }

  return (
    <Wrapper>
      <Label>{label}</Label>
      {/* @ts-ignore */}
      <PinInputGrid
        hasError={hasError && isTouched}
        pin={pin}
        onPaste={onPaste}
        onPinChanged={onPinChanged}
        pinLength={pinLength}
      />
      <ErrorWrapper>
        {showError && (
          <ErrorText>{errorMessage ? errorMessage : error}</ErrorText>
        )}
      </ErrorWrapper>
    </Wrapper>
  )
}

export type PinInputProps = {
  pinLength: number
  label: string
  onChange?: (val: string) => void
  value?: string
  showError?: boolean
  errorMessage?: string
  error?: any
  hasError?: boolean
  isTouched?: boolean
}

export default PinInput

const Wrapper = styled.div`
  padding: 8px 0;
  position: relative;
`

const ErrorWrapper = styled.div`
  position: absolute;
  z-index: 1;
  right: 48px;
`

const ErrorText = styled.p`
  font-weight: 300;
  color: #fc7569;
  font-size: 11px;
`

const Label = styled.label<{ displayRow?: boolean }>`
  font-size: 0.875rem;
  color: #1a1a1a;
  line-height: 1;
  margin-bottom: 4px;
  font-weight: 300;
  display: flex;
  align-items: center;
  flex: 0 0 auto;
`
