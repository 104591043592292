import { createAction } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';

import ConfirmModal from '../modals/ConfirmModal/ConfirmModal';
import InfoModal from '../modals/InfoModal/InfoModal';

export const showModal = createAction('SHOW_MODAL', (component: any, props: any) => {
    return {
        payload: {
            modal: {
                id: uuid(),
                component,
                props,
            },
        },
    };
});

export const hideModal = createAction('HIDE_MODAL', function (id: string, payload: any) {
    return {
        payload,
        meta: {
            id
        }
    };
});

export const clearModals = createAction('CLEAR_MODALS');

export const submitModal = createAction('SUBMIT_MODAL', function (id: string, payload: any) {
    return {
        payload,
        meta: {
            id
        }
    };
});

export const info = createAction('SHOW_INFO_MODAL', (props: any) => {
    return {
        payload: {
            modal: {
                id: uuid(),
                component: InfoModal,
                props,
            },
        },
    };
});

export const confirm = createAction('SHOW_CONFIRM_MODAL', (props: any) => {
    return {
        payload: {
            modal: {
                id: uuid(),
                component: ConfirmModal,
                props,
            },
        },
    };
});
