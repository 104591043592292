import { motion } from "framer-motion";
import styled, { keyframes } from "styled-components";

export const CloseButton = styled.div`
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  cursor: pointer;
  height: 20px;
  padding: 6px;
  position: absolute;
  right: 24px;
  top: 28px;
  width: 20px;
  z-index: 3;

  &:after,
  &:before {
    background: #333;
    content: "";
    height: 20px;
    left: 50%;
    position: absolute;
    top: 0%;
    width: 2px;
  }

  &:after {
    transform: rotate(45deg);
  }

  &:before {
    transform: rotate(-45deg);
  }

  &:hover {
    background: #efefef;
  }
`;

export const ShowAnimation = keyframes`
    from {
        opacity: 0;
        top: -50px;
    }
    to {
        opacity: 1;
        top: 0px;
    }
`;

export const ModalWrapper = styled.div`
  align-items: center;
  animation-duration: 0.5s;
  animation-name: ${ShowAnimation};
  animation-timing-function: ease;
  display: flex;
  height: 100%;
  border-radius: 8px;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  transition: height 0.1s ease-in;
  width: 100%;
  z-index: 1001;

  .ant-form {
    width: 100%;
    display: flex;
    overflow: auto;
    flex-direction: column;
    flex: 0 1 auto;
  }
`;

export const ModalContainer = styled(motion.div)`
  display: flex;
  overflow: visible;
  position: relative;
  flex: 0 0 auto;
  z-index: 1002 !important;
  background-color: white;
  box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.1412);
  overflow: visible;
  border-radius: 8px;
`;

export const ModalOverlay = styled(motion.div)`
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
`;

export const ModalFooter = styled.div`
  display: flex;
  flex-direction: row;
  flex: 0 0 auto;
  justify-content: flex-end;
  padding: ${(props) => (props.noPadding ? 0 : 32)}px;
  padding-top: 0;
  background: #fafafa;
  border-radius: 0 0 8px 8px;
  width: 100%;
  button {
    margin-left: 8px !important;
  }
`;

export const ModalHeader = styled.div`
  flex: 0 0 auto;
  /* padding: 24px; */
  padding-top: 24px;
  padding-left: 24px;
  padding-bottom: 0;
  font-size: 20px;
  font-weight: 500;
  color: #1a1a1a;
`;

export const ModalSection = styled.div<{ mode: "row" | "column" }>`
  display: flex;
  flex-direction: ${(props) => props.mode || "column"};
  flex: 0 1 100%;
  overflow: auto;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 1 100%;
  max-height: 100%;
  overflow: auto;
  width: 100%;
  padding: 24px;
`;

export const ModalTitle = styled.h2.attrs(({ children }) => ({
  title: typeof children === "string" ? children : "",
}))`
  font-size: 18px;
  font-weight: 600;
  line-height: 1.75;
  margin: 0;
  padding: 0;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const ModalSubtitle = styled(ModalTitle).attrs(({ children }) => ({
  title: typeof children === "string" ? children : "",
}))`
  font-size: 16px !important;
  color: #666;
`;
