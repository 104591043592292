import _ from 'lodash';
import { all, put, race, take } from 'redux-saga/effects';

import { createSagas } from '../../../utils/createSagas';

import { preloadActions } from './actions';

const builder = createSagas()

builder.takeLatest(preloadActions.request, function* (action) {
    const { actions }: { actions: any[] } = action.payload

    try {
        yield all(actions.map(action => put(action.request(undefined))))
        yield all(actions.map(action => race({
            success: take(action.success),
            failed: take(action.failed)
        })))
        yield put(preloadActions.success())
    } catch {
        yield put(preloadActions.failed())
    }
})

export default builder
