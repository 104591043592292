import _ from 'lodash';

import { LoadingState } from './types';

export const isLoading = (state: { Loading: LoadingState }, type: Object | string) => {
    const actionType = typeof type === 'object' ? type.toString() : type;

    return state.Loading.loadingStates[actionType] || false;
};

export const isLoadingMultiple = (state: { Loading: LoadingState }, types: (Object | string)[]) => {
    return _.reduce(
        types,
        (acc, next) => {
            const actionType = typeof next === 'object' ? next.toString() : next;
            return acc.concat(state.Loading.loadingStates[actionType]);
        },
        [] as boolean[]
    ).includes(true);
};
