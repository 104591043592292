import React, { FC, useMemo, useCallback } from "react";

import styled from "styled-components";

import { Button, GridRow, Icon } from "../../../base/";
import { Modal } from "../../components/Modal/Modal";
import { ModalContent, ModalHeader, ModalFooter, ModalSection, ModalTitle } from "../../components/Modal/Modal.styles";
import { ConfirmModalProps } from "../../types";

const ConfirmModal: FC<ConfirmModalProps> = ({ id, onClose, onSubmit, width, type, buttons, content, title, height }) => {
  const { submit = "button-ok-label", cancel = "button-abort-label" } = buttons;

  const iconProps = useMemo(() => {
    switch (type) {
      case "warning":
        return { icon: "exclamation-triangle", color: "#f1c40f" };
      case "error":
        return { icon: "bug", color: "#e74c3c" };
      case "success":
        return { icon: "check", color: "#2ecc71" };
      case "info":
        return { icon: "info", color: "#3498db" };
      case "none":
      default:
        return undefined;
    }
  }, [type]);

  const handleSubmit = useCallback(() => {
    onSubmit && onSubmit();
  }, [onSubmit]);

  const handleClose = useCallback(() => {
    onClose && onClose();
  }, [onClose]);

  return (
    <Modal id={id} onClose={onClose} resizable={false} width={width} height={height}>
      <ModalHeader>
        <ModalTitle>{title}</ModalTitle>
      </ModalHeader>
      <ModalContent>
        <ModalSection mode="row">
          {iconProps ? (
            <IconWrapper>
              <ConfirmIcon icon={iconProps.icon} color={iconProps.color} />
            </IconWrapper>
          ) : null}
          <ContentWrapper>{content}</ContentWrapper>
        </ModalSection>
      </ModalContent>
      <GridRow
        style={{
          padding: "24px",
          justifyContent: "end",
          background: "#fafafa",
          borderRadius: "0px 0px 8px 8px",
        }}
      >
        <div style={{ paddingRight: "8px" }}>
          <CloseBtn onClick={handleClose}>{cancel}</CloseBtn>
        </div>
        <SubmitBtn onClick={handleSubmit}>{submit}</SubmitBtn>
      </GridRow>
    </Modal>
  );
};

ConfirmModal.defaultProps = {
  title: "confirm-modal-title",
  content: "confirm-modal-content",
  width: 450,
  buttons: {
    cancel: "button-abort-label",
    submit: "button-ok-label",
  },
};

const ConfirmIcon = styled(Icon)<{ color: string }>`
  svg {
    width: 32px !important;
    height: 32px !important;
  }
  path {
    fill: ${(props) => props.color || "inherit"};
  }
`;

const CloseBtn = styled(Button)`
  position: relative;
  text-align: center;
  background-color: transparent;
  border: 0;
  width: 190px;
`;

const SubmitBtn = styled(Button)`
  position: relative;
  text-align: center;
  width: 190px;
`;

const IconWrapper = styled.div`
  width: 80px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  padding-right: 24px;
`;

const ContentWrapper = styled.div`
  white-space: pre-wrap;
`;

export default ConfirmModal;
