import { createBrowserHistory } from 'history'

const history = createBrowserHistory()
const methods = ['push', 'replace']

Object.keys(history.location).forEach((key) => {
  const upperKey = key.charAt(0).toUpperCase() + key.slice(1)

  methods.forEach((method) => {
    history[method + upperKey] = (prop) => {
      history[method]({
        ...history.location,
        [key]: prop
      })
    }
  })
})

export default history
