import React, { useEffect, useCallback, FC } from 'react'
import styled, { keyframes } from 'styled-components'

import Icon from '../Icon/Icon'

const SubmitButton: FC<SubmitButtonProps> = ({
  shape = 'primary',
  icon,
  disabled,
  children,
  submitting = false,
  ...props
}) => {
  return (
    <SuccessButton
      icon={submitting ? 'loading' : icon}
      submitting={submitting}
      disabled={submitting || disabled}
      {...props}
    >
      {submitting ? 'Lade' : children}
    </SuccessButton>
  )
}

type SubmitButtonProps = ButtonProps &
  React.HTMLAttributes<HTMLButtonElement> & {
    shape?: string
    submitting?: boolean
  }

const Button: FC<ButtonProps> = ({
  children,
  onClick,
  icon,
  className,
  style,
  ...props
}) => {
  const [coords, setCoords] = React.useState({ x: -1, y: -1 })
  const [isRippling, setIsRippling] = React.useState(false)

  useEffect(() => {
    if (coords.x !== -1 && coords.y !== -1) {
      setIsRippling(true)
      setTimeout(() => setIsRippling(false), 300)
    } else setIsRippling(false)
  }, [coords])

  useEffect(() => {
    if (!isRippling) setCoords({ x: -1, y: -1 })
  }, [isRippling])

  const handleClick = useCallback(
    (e) => {
      const rect = e.target.getBoundingClientRect()
      setCoords({ x: e.clientX - rect.left, y: e.clientY - rect.top })
      onClick && onClick(e)
    },
    [onClick]
  )

  return (
    <EditorButton
      className={className}
      tabIndex='0'
      onClick={handleClick}
      icon={icon}
      style={style}
      {...props}
    >
      {icon && (
        <Icon style={{ flex: '1' }} icon={icon} size={20} color='white' />
      )}
      {isRippling ? (
        <span
          className='ripple'
          style={{
            left: coords.x,
            top: coords.y
          }}
        />
      ) : (
        ''
      )}
      {children && (
        <div
          className='content'
          style={icon ? { marginLeft: '10px', flex: '1 1 100%' } : {}}
        >
          {children}
        </div>
      )}
    </EditorButton>
  )
}

export default Button

type ButtonProps = {
  type?: string
  size?: 'small' | undefined
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
  icon?: string
  className?: string
  style?: Record<string, string>
  disabled?: boolean
  children: any
}

const rippleEffect = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(10);
    opacity: 0.375;
  }
  100% {
    transform: scale(35);
    opacity: 0;
  }
`

const EditorButton = styled.button<any>`
  display: flex;
  font-family: 'Lato', sans-serif;
  width: 160px;
  line-height: 19px;
  height: 45px;
  transition: all 0.3s ease-in-out;
  border: 2px solid #a35451;
  background-color: #a35451;
  padding: 8px 16px;
  color: white;
  border-radius: 5px;
  align-items: center;
  font-weight: 500;
  text-align: center;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    background-color: #bb6360;
    border: 2px solid #bb6360;
  }

  .ripple {
    width: 20px;
    height: 20px;
    position: absolute;
    background: #c08280;
    display: block;
    content: '';
    border-radius: 9999px;
    opacity: 1;
    animation: 0.9s ease 1 forwards ${rippleEffect};
  }

  .content {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: center;
    flex: 0 1 100%;
    height: 100%;
    align-items: center;
  }

  &:disabled {
    background: #ccc;
    pointer-events: none;
    border: 2px solid #ccc;
  }
`

export const SecondaryButton = styled<any>(Button)`
  background: transparent;
  border: 2px solid transparent;
  color: black;
  &[disabled] {
    opacity: 0.8;
    cursor: not-allowed;
  }
  span {
    color: black;
  }
  path,
  circle {
    fill: black;
  }
  &:hover {
    background: #e4e4e4;
    border: 2px solid transparent;
  }

  .ripple {
    background: #ddd;
  }
`

export const PrimaryButton = styled<any>(Button)`
  background: #00737f;
  border: 2px solid #00737f;
  color: white;
  &[disabled] {
    opacity: 0.8;
    cursor: not-allowed;
  }
  span {
    color: #ffffff;
  }
  path,
  circle {
    fill: #ffffff;
  }
  &:hover {
    background: #1f808c;
    border: 2px solid #1f808c;
  }

  .ripple {
    background: #006672;
  }
`

export const SuccessButton = styled(Button)`
  position: inherit;
`

export const ErrorButton = styled(Button)`
  background: ${(props) => (props.deactivated ? '#00cf82' : '#e74c3c')};
  border: ${(props) =>
    props.deactivated ? '1px solid #00cf82' : '1px solid #e74c3c'};
  height: 40px;
  color: #fff;
  path {
    fill: #ffffff;
  }
  &:hover {
    background: ${(props) => (props.deactivated ? '#00cf82' : '#fb5e4b')};
    border: ${(props) =>
      props.deactivated ? '1px solid #00cf82' : '1px solid #fb5e4b'};
    span {
      color: white;
    }
    path {
      fill: white;
    }
  }

  .ripple {
    background: ${(props) => (props.deactivated ? '#00cf82' : '#d3392d')};
  }
`

export const WarningButton = styled(Button)`
  background: #f1c40f;
  border: 1px solid #f1c40f;
  color: #fff;
  span {
    color: #ffffff;
  }
  path {
    fill: #ffffff;
  }
  &:hover {
    background: white;
    border: 1px solid #f1c40f;
    span {
      color: #f1c40f;
    }
    path {
      fill: #f1c40f;
    }
  }
`
