import React, { FC, ReactElement, useState, useCallback } from "react";
import styled from "styled-components";
import { AnimateSharedLayout, motion } from "framer-motion";
const Tabs: FC<TabsProps> = ({ children, sticky, activeColor, color }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <TabWrapper>
      <List sticky={sticky}>
        {children.map((item, index) => (
          <TabTitle
            color={color}
            activeColor={activeColor}
            key={index}
            title={item.props.title}
            index={index}
            isActive={index === selectedTab}
            setSelectedTab={setSelectedTab}
          />
        ))}
      </List>
      <TouchWrapper>
        <AnimateSharedLayout>{children[selectedTab]}</AnimateSharedLayout>
      </TouchWrapper>
    </TabWrapper>
  );
};

export const TabTitle: React.FC<TabTitleProps> = ({ title, setSelectedTab, index, isActive, color, activeColor }) => {
  const onClick = useCallback(() => {
    setSelectedTab(index);
  }, [setSelectedTab, index]);

  return (
    <TabButton color={color} activeColor={activeColor} isActive={isActive} onClick={onClick}>
      {title}
      {isActive && <BackgroundBox layoutId="box_settings" />}
      {isActive && <Underline layoutId="underline_settings" />}
    </TabButton>
  );
};

export default Tabs;

type TabsProps = {
  children: ReactElement[];
  sticky?: boolean;
  activeColor: string;
  color: string;
};

type TabTitleProps = {
  title: string;
  index: number;
  isActive: boolean;
  color: string;
  activeColor: string;
  setSelectedTab: (index: number) => void;
};

const BackgroundBox = styled(motion.div)<{
  isActive: boolean;
  activeColor: string;
  color: string;
}>`
  /* background: ${(p) => p.activeColor}; */
  background: #00737f10;
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 4px 4px 0 0;
  left: 0px;
  top: 0px;
  z-index: 0;
`;

const TabButton = styled.button<{
  isActive: boolean;
  activeColor: string;
  color: string;
}>`
  cursor: pointer;
  padding: 16px;
  font-weight: 500;
  border: unset;
  z-index: 1;
  border-radius: 4px 4px 0 0;
  transition: all 0.3s ease-in-out;
  background: transparent;
  /* border-bottom: 1px solid #efefef; */
  /* background-color: ${(p) => (p.isActive ? p.activeColor : "transparent")}; */
  color: ${(p) => (p.isActive ? "#00737f" : "#9A9A9A")};
  user-select: none;
  position: relative;
  &:hover {
    color: #1a1a1a;
  }
`;

const TabWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  /* height: 48px; */
`;

const Underline = styled(motion.div)`
  height: 2px;
  width: 100%;
  position: absolute;
  border-radius: 4px 4px 0 0;
  background: #00737f;
  bottom: 0px;
  left: 0px;
`;

const List = styled.div<{
  sticky: boolean;
}>`
  display: flex;
  border-bottom: 1px solid #efefef;
  position: ${(p) => (p.sticky ? "fixed" : "relative")};
  /* background-color: ${(p) => (p.sticky ? "#f9f9f9" : "transparent")}; */
  position: relative;
  width: 100%;
  @media only screen and (max-width: 600px) {
    background-color: #fff;
  }
`;

const TouchWrapper = styled.div`
  /* height: 48px; */
  height: 100%;
  overflow: scroll;
  height: 80vh;
`;
