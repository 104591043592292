import { styled } from 'core'

import Container from '../Container/Container'

const GridRow = styled(Container)`
  gap: ${(props) => props.gap || ''};
  width: ${(props) => props.width || '100%'};
  padding: ${(props) => props.padding || ''};
  display: ${(props) => props.display || 'flex'};
  justify-content: ${(props) => props.justifyContent};
  flex-direction: ${(props) => props.flexDirection || 'row'};
  align-items: ${(props) => props.alignItems || 'center'};
  flex: ${(props) => props.flex || '0 0 auto'};
  position: ${(props) => props.position || 'inherit'};
`

export default GridRow
