import { React } from 'core'
import { Route } from '../../routes'

import renderWithLayout from '../../../utils/renderWithLayout'
import history from '../../history'

const Poster = React.lazy(() => import('./pages/Poster.container'))
const PosterImg = React.lazy(() => import('./pages/PosterImg.container'))
const path = history?.location?.pathname

const routes: Route[] = [
  {
    path: '/poster/:id',
    name: 'Poster-File',
    exact: true,
    render: (props) => <PosterImg {...props} />
  },
  {
    path: '/editor',
    name: 'Poster',
    exact: true,
    render: (props) => <div style={{ fontSize: '30px', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>Comming Soon...</div>
  }
]

export default routes
