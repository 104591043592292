import { RootState } from '../index'
import { Poster } from './types'

export const getPoster = (state: RootState): Poster => state.Poster.poster

export const getIsSelectVariants = (state: RootState) => state.Poster.isSelectVariants

export const getPosterIsLoading = (state: RootState) => state.Poster.isLoading

export const getCheckoutIsLoading = (state: RootState) => state.Poster.checkOutIsLoading
