import { middlewares } from './middlewares'
import { configureStore } from '@reduxjs/toolkit'

import combineReducers from './reducer'

const reducer = (state, action) => {
  return combineReducers(state, action)
}

const store = configureStore({
  reducer: reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }).concat(middlewares)
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store
