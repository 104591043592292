import { FC } from 'react'
import { React, styled } from 'core'
import { useLottie } from 'lottie-react'

const Lottie: FC<LottieProps> = ({ lottie, loop, width, height, from, to }) => {
  const options = {
    animationData: lottie,
    loop: loop,
    autoplay: true,
  };

  const { View, playSegments } = useLottie(options);

  const onClick = (from: number, to: number) => {
    playSegments([from, to]);
  };

  return (
    <Wrapper onClick={() => onClick(from, to)} width={width} height={height}>
      {View}
    </Wrapper>
  );
};

type LottieProps = {
  lottie: any;
  loop: boolean;
  width?: number;
  height?: number;
  from?: number | undefined;
  to?: number | undefined;
};

export default Lottie;

const Wrapper = styled.div<{
  width?: number;
  height?: number;
}>`
  width: ${(props) => (props.width ? `${props.width}px` : "200px")};
  height: ${(props) => (props.height ? `${props.height}px` : "200px")};
`;
