import { ToastContainer, ToastSlide, GlobalStyle } from 'ui'
import { React, createRoot, Provider, Router, Switch, Route } from 'core'

import store from './store'
import history from './history'
import ModuleRouter from './ModuleRouter'

const root = createRoot(document.getElementById('root')!)

root.render(
  <Provider store={store}>
    <Router history={history}>
      <GlobalStyle />
      <Switch>
        <Route render={() => <ModuleRouter />} />
      </Switch>
      <ToastContainer
        theme='colored'
        position='top-right'
        autoClose={4000}
        pauseOnFocusLoss={false}
        transition={ToastSlide}
      />
    </Router>
  </Provider>
)

export default root
