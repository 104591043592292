
const requireBasicIcons = require.context('../../../../assets/icons', false, /\.svg$/)
const nameToSourceBasic = requireBasicIcons.keys().map((x: string) => ({
  name: extractIconName(x),
  data: requireBasicIcons(x).default,
}))

export const icons = toRecord(nameToSourceBasic)

function extractIconName(x: string): string {
  return (new RegExp(/(?:\/)(.*)(?=.svg)/gm)!.exec(x)![0] || '').replace('/', '').replace('icn-', '')
}

function toRecord<T extends Record<string, string> = Record<string, string>>(xs: Record<string, string>[]): T {
  return xs.reduce((acc, next) => Object.assign(acc, { [next.name]: next.data }), {}) as T
}

export const getIcon = (icon: string) => {
  return icons[icon]
}
