import { createReducer } from '@reduxjs/toolkit';

import * as actions from './actions';
import { ModalState } from './types';

const initialState: ModalState = {
    modals: [],
};

export default createReducer(initialState, (builder) => {
    builder.addCase(actions.showModal, (state, action) => {
        state.modals.push(action.payload.modal);
    });

    builder.addCase(actions.clearModals, (state) => {
        state.modals = []
    });

    builder.addCase(actions.info, (state, action) => {
        state.modals.push(action.payload.modal);
    });

    builder.addCase(actions.confirm, (state, action) => {
        state.modals.push(action.payload.modal);
    });

    builder.addCase(actions.hideModal, (state, action) => {
        if (action.meta.id) {
            state.modals = state.modals.filter((e) => e.id !== action.meta.id);
        } else {
            state.modals.pop();
        }
    });

    builder.addCase(actions.submitModal, (state, action) => {
        if (action.meta.id) {
            state.modals = state.modals.filter((e) => e.id !== action.meta.id);
        } else {
            state.modals.pop();
        }
    });
});
