import { FC } from 'react'
import { React } from 'core'

const ToastMessage: FC<ToastMessageProps> = ({ message }) => {
  return <p>{ message } </p>
}

type ToastMessageProps = {
  message: string
}

export default ToastMessage
