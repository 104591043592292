import React, { FC, ReactElement } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
type TabProps = {
  title: string;
  children: ReactElement;
};

const Tab: FC<TabProps> = ({ children }) => {
  return <TabContent>{children}</TabContent>;
};

export default Tab;

const TabContent = styled(motion.div)`
  padding-top: 16px;
  height: 100%;
  width: 100%;
`;
