/* eslint-disable @typescript-eslint/ban-ts-comment */
import { spawn, takeEvery, takeMaybe, takeLatest, takeLeading, take } from 'redux-saga/effects';
import { ActionCreator } from '@reduxjs/toolkit'

export const createSagas = () => {
    const sagaConfigs = []

    return {
        // @ts-ignore
        takeLatest: <T>(action: ActionCreator<T>, handler: (action: ReturnType<T>) => unknown) => {
            sagaConfigs.push({
                effect: 'takeLatest',
                action,
                handler
            })
        },

        // @ts-ignore
        takeMaybe: <T>(action: T, handler: (action: ReturnType<T>) => unknown) => {
            sagaConfigs.push({
                effect: 'takeMaybe',
                action,
                handler
            })
        },

        // @ts-ignore
        takeEvery: <T>(action: T, handler: (action: ReturnType<T>) => unknown) => {
            sagaConfigs.push({
                effect: 'takeEvery',
                action,
                handler
            })
        },

        // @ts-ignore
        takeLeading: <T>(action: T, handler: (action: ReturnType<T>) => unknown) => {
            sagaConfigs.push({
                effect: 'takeLeading',
                action,
                handler
            })
        },

        *run() {
            for (const sagaConfig of sagaConfigs) {
                const { effect, action, handler } = sagaConfig
                try {
                    yield spawn(function* () {
                        yield possibleEffects[effect](action, handler)
                    })
                } catch { }
            }
        }
    }

}

const possibleEffects = {
    *take(action, actionHandler) {
        yield take(action, actionHandler)
    },
    *takeLatest(action, actionHandler) {
        yield takeLatest(action, actionHandler)
    },
    *takeEvery(action, actionHandler) {
        yield takeEvery(action, actionHandler)
    },
    *takeMaybe(action, actionHandler) {
        yield takeMaybe(action, actionHandler)
    },
    *takeLeading(action, actionHandler) {
        yield takeLeading(action, actionHandler)
    }
}