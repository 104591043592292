import { createAction } from 'redux-actions'
import { Cart } from './types'

import * as CARTAPI from './api'

export const fetchCart = createAction('CART/FETCH', () => {
  return CARTAPI.fetchCart()
})

export const setCurrentStep = createAction('SET/STEP', (step: number) => {
  return step
})

export const saveImgPng = createAction('SAVE/IMG-PNG', ({ imageData }) => {
  return CARTAPI.saveImgPng({ imageData })
})

export const addProduct = createAction('CART/ADD/PRODUCT', ({ cartId, lineItemsToAdd }) =>
  CARTAPI.addProduct({ cartId, lineItemsToAdd })
)

export const addPoster = createAction('POSTER/ADD', ({ id, poster }) => CARTAPI.addPoster({ id, poster }))

export const fetchProducts = createAction('CART/PRODUCTS/FETCH', () => CARTAPI.fetchProducts())

export const fetchPoster = createAction('POSTER/FETCH', (fileKey) => CARTAPI.fetchPoster(fileKey))
