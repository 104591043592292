import { styled } from 'core'

const ErrorMessage = styled.span`
  color: #fc7569;
  width: 100%;
  min-height: 19px;
  font-size: 11px;
  text-align: left;
  display: block;
  padding: 2px;
`

export default ErrorMessage
