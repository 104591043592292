import { handleActions, update } from 'core'
import { CartState } from './types'
import {
  fetchProducts,
  fetchCart,
  addProduct,
  fetchPoster,
  setCurrentStep
} from './actions'
import { getAudioFile, getPosterFile } from '../Poster'

const initialState: CartState = {
  cart: undefined,
  currentStep: 0,
  products: [],
  downloadedPoster: undefined,
  downloadedAudio: undefined
}

const handleFetchCart = (state, action) => {
  return update(state, {
    cart: {
      $set: action.payload
    }
  })
}

const handleSetCurrentStep = (state, action) => {
  return update(state, {
    currentStep: {
      $set: action.payload
    }
  })
}

const handleFetchProducts = (state, action) => {
  return update(state, {
    products: {
      $set: action.payload
    }
  })
}

const handleFetchPoster = (state, action) => {
  return update(state, {
    downloadedPoster: {
      $set: action.payload
    }
  })
}

const handleFetchPosterAudio = (state, action) => {
  return update(state, {
    downloadedAudio: {
      $set: action.payload
    }
  })
}

const handleAddProduct = (state, action) => {
  return update(state, {
    cart: {
      $set: action.payload
    }
  })
}

export default handleActions(
  {
    [fetchCart.toString()]: handleFetchCart,
    [fetchProducts.toString()]: handleFetchProducts,
    [addProduct.toString()]: handleAddProduct,
    [getAudioFile.toString()]: handleFetchPosterAudio,
    [getPosterFile.toString()]: handleFetchPoster,
    [setCurrentStep.toString()]: handleSetCurrentStep
  },
  initialState
)
