import styled from 'styled-components'
import React, { useEffect, useRef } from 'react'

const PinInputGrid: React.FC<PinInputGridProps> = ({
  onPinChanged,
  hasError,
  pin,
  pinLength,
  onPaste
}) => {
  const PIN_MIN_VALUE = 0
  const PIN_MAX_VALUE = 9
  const BACKSPACE_KEY = 'Backspace'

  const inputRefs = useRef<HTMLInputElement[]>([])

  const changePinFocus = (pinIndex: number) => {
    const ref = inputRefs.current[pinIndex]
    if (ref) {
      ref.focus()
    }
  }

  useEffect(() => {
    changePinFocus(0)
  }, [])

  const onChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const value = event.target.value
    const pinNumber = Number(value.trim())

    if (isNaN(pinNumber) || value.length === 0) {
      return
    }

    if (pinNumber >= PIN_MIN_VALUE && pinNumber <= PIN_MAX_VALUE) {
      onPinChanged(pinNumber, index)
      if (index < pinLength - 1) {
        changePinFocus(index + 1)
      }
    }
  }

  const onKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    const keyboardKeyCode = event.nativeEvent.code

    if (event.ctrlKey && event.nativeEvent.code === '86') {
      return onPaste()
    }
    if (keyboardKeyCode !== BACKSPACE_KEY) {
      return
    }

    if (pin[index] === undefined) {
      changePinFocus(index - 1)
    } else {
      onPinChanged(undefined, index)
    }
  }

  return (
    <OuterWrapper>
      {Array.from({ length: pinLength }, (_, index) => (
        <StyledInput
          hasError={hasError}
          key={index}
          ref={(el) => {
            if (el) {
              inputRefs.current[index] = el
            }
          }}
          onChange={(event) => onChange(event, index)}
          value={pin[index] || ''}
          onKeyDown={(event) => onKeyDown(event, index)}
          onPaste={onPaste}
        />
      ))}
    </OuterWrapper>
  )
}

type PinInputGridProps = {
  pin: Array<string | undefined>
  onPinChanged: (pinEntry: number, index: number) => void
  pinLength: number
  onPaste: any
  hasError: boolean
}

export default PinInputGrid

const OuterWrapper = styled.div`
  display: flex;
  padding: 8px 0px;
  justify-content: space-between;
`

const StyledInput = styled.input<{ hasError: boolean }>`
  overflow: hidden;
  position: relative;
  font-weight: normal;
  background: ${(props) => (props.disabled ? '#f1f1f1' : '#fff')};
  border: ${(props) =>
    props.hasError ? '1px solid #FC7569' : '1px solid #efefef'};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '4px'};
  margin-left: ${(props) => props.marginLeft};
  margin-right: ${(props) => props.marginRight};
  padding: 0 16px;
  font-size: 16px;
  outline: none;
  width: 50px;
  color: #1a1a1a;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  z-index: 1;
  border-radius: 4;
  color: #3f4650;
  height: 50px;
  background-color: #f2f2f2;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  &:hover {
    border: ${(props) =>
      props.hasError ? '1px solid #FC7569' : '1px solid #00737f'};
  }

  &:focus {
    border: ${(props) =>
      props.hasError ? '1px solid #FC7569' : '1px solid rgb(0 106 119)'};
    box-shadow: 0 0 0 3px rgb(0 106 119 / 20%);
  }
`
