import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
#root {
  display: flex;
  flex: 0 1 100%;
  width: 100vw;
  height: 100vh;
  background: #f2f2f2;

  /* @media only screen and (max-width: 442px) {
    height: 50vh;
  } */
}

:root {
  font-size: 16px;
}

#defaultCanvas0 {
  width: 100% !important;
  height: 500px !important;
}

.react-reveal {
  display: flex;
  flex: 0 1 100%;
  max-height: 100%;
  width: 100%;
  z-index: 101;
  justify-content: center;
  overflow: auto;
}

body {
  margin: 0;
  font-family: 'Heebo';
  font-weight: 500;
  font-size: 100%;

  #layers {
    z-index: 99999;
  }

    .custom-select__control {
    min-height: 50px;
    background-color: #f2f2f2;
    display: flex;
    flex: 1;
    border: 1px solid #efefef;
    font-weight: 400;
    color: #1a1a1a;
    font-size: 14px;
    box-shadow: 0 0 0 3px rgb(0 106 119 / 0%);
    border-radius: 4;
    transition: all 0.3s ease-in-out;
    &:hover {
      border: 1px solid #00737f;
    }
  }
  .custom-select__menu {
    z-index: 3;
    padding: 0rem;
    border: 1px solid #efefef;
    background: #fff;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }
  .custom-select__menu-list {
    border-radius: 4px;
    padding: 0rem;
    background: #fff;
    color: #1a1a1a;
  }
  .custom-select__option {
    padding: 16px;
    border-bottom: 1px solid #efefef;
    font-size: 14px;
    background: #fff;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    color: #1a1a1a;
    &:hover {
      background: #fc0;
      color: #1a1a1a;
    }
  }
  .custom-select__indicator-separator {
    background: transparent;
  }
  .custom-select__dropdown-indicator {
    path {
      background: #7b7b7b;
    }
  }
  .custom-select__value-container--has-value {
    color: #fc0;
    .optionLabelIcon {
      display: none;
    }
    .optionLabelText {
      padding: 0px;
    }
  }
  .custom-select__multi {
    .optionLabelText {
      color: #ccc;
    }
  }
  .custom-select__input-container {
    color: #1a1a1a;
  }
  .custom-select__multi-value__remove {
    cursor: pointer;
    color: #1a1a1a;
    transition: all 0.3s ease-in-out;
    border-radius: 0px 4px 4px 0px;
    height: 30px;
    &:hover {
      background-color: #e7b900;
      color: #1a1a1a;
    }
  }
  .custom-select__single-value {
    color: #1a1a1a;
  }
  .custom-select__placeholder {
    font-size: 14px;
    padding-left: 8px;
    font-weight: 400;
    color: #1a1a1a;
  }
  .custom-select__multi-value {
    background: #fc0;
    &:hover {
      background: #ffd21c;
    }
    transition: all 0.3s ease-in-out;
    border-radius: 4px;
  }
  .custom-select__multi-value__label {
  }
}

* {
  box-sizing: border-box;
  font-smooth: auto;
  text-rendering: geometricPrecision;
  font-size: 0.875rem;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 16px 0;
}

h4 {
  font-weight: 400;
}

p {
  font-size: 0.875rem;
}

.react-toggle {
  touch-action: pan-x;

  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 40px;
  height: 16px;
  padding: 0;
  border-radius: 30px;
  background-color: #4d4d4d;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #000000;
}

.react-toggle--checked .react-toggle-track {
  background-color: #19ab27;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #128d15;
}

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 14px;
  height: 14px;
  border: 1px solid #4d4d4d;
  border-radius: 50%;
  background-color: #fafafa;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
  left: 27px;
  border-color: #19ab27;
}

.react-toggle--focus .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 3px 2px #0099e0;
  -moz-box-shadow: 0px 0px 3px 2px #0099e0;
  box-shadow: 0px 0px 2px 3px #0099e0;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 5px 5px #0099e0;
  -moz-box-shadow: 0px 0px 5px 5px #0099e0;
  box-shadow: 0px 0px 5px 5px #0099e0;
}

.custom-react-tooltip {
  overflow: visible;
  z-index: 1000000;
}

@media only screen and (max-width: 1250px) {
  .plyr {
    position: absolute !important;
    top: 65% !important;
  }
}

@media only screen and (max-width: 375px) {
    .plyr {
      position: absolute !important;
      top: 65% !important;
    }
  }

`

export default GlobalStyle
