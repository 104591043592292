import { put, take, call, race, cancel } from 'redux-saga/effects'
import { v4 as uuid } from 'uuid'

import * as actions from '../service/actions'
import { ConfirmModalProps, InfoModalProps } from '../types'

function* close(id?: string, payload?: any) {
  const action = actions.hideModal(id, payload)

  yield put(action)
}

export type OpenModalGeneratorProps = {
  onSubmit?: any
  onClose?: any
}

function* open(
  component,
  { onSubmit, onClose, ...props }: OpenModalGeneratorProps | any
) {
  const action = actions.showModal(component, props)

  yield put(action)

  const { submitAction, closeAction } = yield race({
    closeAction: take(actions.hideModal),
    submitAction: take(actions.submitModal)
  })

  if (submitAction) {
    const { payload } = submitAction
    yield call(onSubmit, payload)
  } else {
    const { payload } = closeAction
    yield call(onClose, payload)
  }

  return submitAction
}

function* info({ content, title, subtitle, buttons }: InfoModalProps) {
  const action = actions.info({
    id: uuid(),
    content,
    subtitle,
    title,
    buttons,
    onSubmit: () => {},
    onClose: () => {}
  })

  yield put(action)

  const { submittAction } = yield race({
    closed: take(actions.hideModal),
    submittAction: take(actions.submitModal)
  })

  return submittAction
}

function* confirm({
  content,
  title,
  buttons
}: Omit<ConfirmModalProps, 'onSubmit'>) {
  const action = actions.confirm({
    id: uuid(),
    content,
    title,
    buttons,
    onSubmit: () => {},
    onClose: () => {}
  })

  yield put(action)

  const { submitAction, closed } = yield race({
    closed: take(actions.hideModal),
    submitAction: take(actions.submitModal)
  })

  if (closed) {
    yield cancel()
  }

  return submitAction
}

const modal = {
  close,
  open,
  info,
  confirm
}

export default modal
