/* eslint-disable max-lines-per-function */
import _ from 'lodash';

import { LoadingState } from './types';

const initialState: LoadingState = {
    loadingStates: {},
};

enum ActionStates {
    Request = "request"
}

export default (state: LoadingState = initialState, action) => {
    const { meta = {} } = action;

    if (meta.async) {
        return {
            ...state,
            loadingStates: {
                ...state.loadingStates,
                [meta.type]: meta.state === ActionStates.Request ? true : false,
            },
        };
    }
    return state;
};
