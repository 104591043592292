import store from './Store/'

//@ts-ignore
window.store = store

export const getStore = () => {
  return store
}

export default store
