import { FC } from 'react'
import { React, styled } from 'core'

import Lottie from 'react-lottie-player'
import LoveLoader from '../../../../assets/lotties/loader.json'

const Loader: FC<LoaderProps> = ({ size = 200 }) => (
  <Wrapper>
    <Lottie animationData={LoveLoader} loop={true} play={true} style={{ width: size, height: size }} />
  </Wrapper>
)

type LoaderProps = {
  size?: number
}

export default Loader

const Wrapper = styled.div`
  display: flex;
  margin: auto;
`

export const LoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 1 100%;
  width: 100%;
  height: 90%;
  justify-content: center;
  align-items: center;
  /* margin-top: 50px; */
`
