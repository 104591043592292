// eslint-disable-next-line no-restricted-imports
import React, { FunctionComponent } from "react";

import styled from "styled-components";

const ModalActions: FunctionComponent<ModalActionProps> = ({ closable, onClose }) => {
  return (
    <ModalActionWrapper>
      {closable ? (
        <ModalAction onClick={onClose}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" fill="#000000">
            <path
              d="M 39.486328 6.9785156 A 1.50015 1.50015 0 0 0 38.439453 7.4394531 L 24 21.878906 L 9.5605469 7.4394531 A 1.50015 1.50015 0 0 0 8.484375 6.984375 A 1.50015 1.50015 0 0 0 7.4394531 9.5605469 L 21.878906 24 L 7.4394531 38.439453 A 1.50015 1.50015 0 1 0 9.5605469 40.560547 L 24 26.121094 L 38.439453 40.560547 A 1.50015 1.50015 0 1 0 40.560547 38.439453 L 26.121094 24 L 40.560547 9.5605469 A 1.50015 1.50015 0 0 0 39.486328 6.9785156 z"
              fill="#000000"
            />
          </svg>
        </ModalAction>
      ) : null}
    </ModalActionWrapper>
  );
};

interface ModalActionProps {
  onClose?: (ev: unknown) => void;
  onMaximize?: (ev: unknown) => void;
  onMinimize?: (ev: unknown) => void;
  resizable?: boolean;
  closable?: boolean;
  state?: any;
}

const ModalAction = styled.div`
  display: flex;
  border: 0;
  background: #f7f7f7;
  padding: 4px;
  margin-top: -4px;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  &:hover {
    background: #efefef;
  }
  svg {
    height: 20px;
    width: 20px;
  }
  path {
    fill: #333;
  }
  &[disabled] {
    cursor: not-allowed;
    path {
      fill: #ddd;
    }
  }
`;

const ModalActionLink = styled.a`
  display: flex;
  border: 0;
  background: none;
  padding: 0;
  cursor: pointer;
  svg {
    height: 20px;
    width: 20px;
  }
  path {
    fill: #333;
  }
  &[disabled] {
    cursor: not-allowed;
    path {
      fill: #ddd;
    }
  }
`;

const ModalActionWrapper = styled.div`
  display: flex;
  position: absolute;
  right: 24px;
  top: 28px;
  z-index: 100;
`;

export default ModalActions;
