import { FC } from 'react'
import { Redirect } from 'router'
import { React, Switch, Route, Router as ReactRouter } from 'core'

import routes from './routes'
import history from './history'

const Router: FC<{}> = () => {
  return (
    <ReactRouter history={history}>
      <Switch>
        {routes.map((route: Route, i) => {
          return <Route key={i} path={route.path} name={route.name} exact={route.exact} render={(props) => route.render(props)} />
        })}
        <Route render={() => <Redirect to='/editor' />} />
      </Switch>
    </ReactRouter>
  )
}

export default Router
