import React, { ReactElement } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useState, FC } from "react";
import { Icon } from "base";
import styled from "styled-components";

const Accordion: FC<AccordionProps> = ({ children, title, isOpen = false }) => {
  const [expanded, setExpanded] = useState<boolean>(isOpen);

  // By using `AnimatePresence` to mount and unmount the contents, we can animate
  // them in and out while also only rendering the contents of open accordions
  return (
    <Wrapper>
      <Header initial={false} onClick={() => setExpanded(expanded ? false : true)}>
        <Title>{title}</Title>
        <motion.div animate={{ rotate: expanded ? "180deg" : "0deg" }} transition={{ duration: 0.2, ease: "easeInOut" }}>
          <Icon size={25} icon="arrow_up" color={"#00737f"} />
        </motion.div>
      </Header>
      <AnimatePresence initial={false}>
        {expanded && (
          <Section
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: "auto" },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.4, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            {children}
          </Section>
        )}
      </AnimatePresence>
    </Wrapper>
  );
};

type AccordionProps = {
  isOpen?: boolean;
  title: string;
  children: ReactElement;
};

export default Accordion;

const Title = styled.p`
  font-size: 14px;
  font-weight: bold;
  /* color: #00737f; */
  color: #1a1a1a;
  user-select: none;
`;

const Header = styled(motion.header)`
  color: #00737f;
  /* background: #00737f10; */
  border-radius: 0px;
  cursor: pointer;
  /* border-radius: 4px 4px 0px 0px; */
  /* border-radius: 4px; */
  padding: 16px;
  /* margin-bottom: 20px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.3s ease-in-out;
  &:hover {
    background: #00737f10;
  }
`;

const Section = styled(motion.section)`
  border: 1px solid #f9f9f9;
`;
const Wrapper = styled.div`
  /* border-bottom: 1px solid #efefef; */
`;
