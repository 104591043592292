import { styled, React } from 'core'
import { FC, HTMLProps } from 'react'
import { useField } from 'react-final-form'
import { Icon, InfoTooltip } from 'base'
import { FieldValidator } from 'final-form'
import { Arrow, useLayer } from 'react-laag'

export const Input: FC<HTMLProps<HTMLInputElement> & InputProps> = ({
  name,
  label,
  placeholder,
  hasError,
  isLoading,
  error,
  isValid,
  isValidating,
  isTouched,
  bgColor,
  icon,
  maxWidth,
  autoComplete,
  flex,
  paddingRight,
  paddingLeft,
  errorMessage,
  displayRow,
  info,
  ...props
}) => {
  if (props.value) {
    errorMessage = undefined
  }

  const showError: boolean = (error && isTouched) || !!errorMessage

  // const { renderLayer, triggerProps, layerProps, triggerBounds, arrowProps } = useLayer({
  //   isOpen: showError,
  //   overflowContainer: true,
  //   auto: true,
  //   snap: true,
  //   placement: "top-start",
  //   possiblePlacements: ["top-start", "bottom-start", "right-center", "left-center"],
  //   triggerOffset: 12,
  //   containerOffset: 16,
  //   arrowOffset: 8
  // });

  return (
    <OuterWrapper
      paddingRight={paddingRight}
      paddingLeft={paddingLeft}
      maxWidth={maxWidth}
      flex={flex}
    >
      <InnerWrapper hasError={hasError && isTouched} displayRow={displayRow}>
        <Label displayRow={displayRow} htmlFor={name}>
          {label}
        </Label>
        <InputElementWrapper>
          <InputElement
            // {...triggerProps}
            icon={icon}
            placeholder={placeholder}
            isValid={isValid || isTouched}
            hasError={hasError && isTouched}
            autoComplete={autoComplete}
            id={name}
            {...props}
          />
          {icon && (
            <IconWrapper>
              <Icon size={18} icon={icon} />
            </IconWrapper>
          )}
          {!!info && (
            <InfoTooltip text={info}>
              <InfoWrapper displayRow={displayRow}>
                <Icon size={18} icon={'info'} />
              </InfoWrapper>
            </InfoTooltip>
          )}
        </InputElementWrapper>
        <ErrorWrapper>
          {showError && (
            <ErrorText>{errorMessage ? errorMessage : error}</ErrorText>
          )}
        </ErrorWrapper>
        {/* {showError &&
        renderLayer(
          <ReqList
            {...layerProps}
            style={{
              ...layerProps.style,
              width: triggerBounds!.width,
            }}
          >
            {error}
            <Arrow {...arrowProps} />
          </ReqList>
        )} */}
      </InnerWrapper>
    </OuterWrapper>
  )
}

export const FormInput: FC<FormInputProps> = ({
  name,
  displayRow,
  label,
  errorMessage,
  validate,
  placeholder,
  borderRadius,
  ...restInput
}) => {
  const { input, meta } = useField(name, { validate })
  const { touched, valid, error, validating, dirtySinceLastSubmit } = meta
  const errMessage =
    !dirtySinceLastSubmit && errorMessage
      ? errorMessage
      : error
      ? error
      : undefined

  return (
    <Input
      displayRow={displayRow}
      placeholder={placeholder}
      label={label}
      isTouched={touched}
      isValid={valid}
      borderRadius={borderRadius}
      hasError={!!error}
      isValidating={validating}
      error={errMessage}
      errorMessage={errorMessage}
      {...restInput}
      {...input}
    />
  )
}

interface InputProps extends HTMLProps<HTMLInputElement> {
  info?: string
  name: string
  label?: string
  hasError?: boolean
  isLoading?: boolean
  error?: boolean
  type?: string
  isValid?: boolean
  isValidating?: boolean
  isTouched?: boolean
  bgColor?: string
  icon?: string
  maxWidth?: string
  flex?: string
  paddingLeft?: string
  paddingRight?: string
  borderRadius?: string
  errorMessage?: string
  displayRow?: boolean
}

interface FormInputProps extends InputProps {
  name: string
  label?: string
  placeholder?: string
  validate?: FieldValidator<any> | undefined
  errorMessage?: string | undefined
  borderRadius?: string
  icon?: string
  style?: any
  displayRow?: boolean
}

const InfoWrapper = styled.button<{ displayRow: boolean }>`
  position: absolute;
  right: 36px;
  top: ${(p) => (p.displayRow ? '20px' : '49px')};
  z-index: 2;
  transition: all 0.3s ease-in-out;
  padding: 0;
  border: none;
  margin: 0;
  cursor: pointer;
  path {
    transition: all 0.3s ease-in-out;
    fill: #ccc !important;
  }
  &:hover {
    path {
      fill: #03332f !important;
    }
  }
`

const IconWrapper = styled.div`
  position: absolute;
  left: 18px;
  top: 46px;
  z-index: 0;
`

const InputElementWrapper = styled.div`
  display: flex;
  flex: 1;
  padding-top: 3px;
  padding-bottom: 8px;
  path {
    fill: #ccc;
  }
`

const ReqList = styled.div`
  background-color: white;
  z-index: 1003;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
  padding: 16px;
`

const ErrorWrapper = styled.div`
  position: absolute;
  z-index: 1;
  right: 8px;
  bottom: 12px;
`

const ErrorText = styled.p`
  font-weight: 300;
  color: #fc7569;
  font-size: 11px;
`

const Label = styled.label<{ displayRow?: boolean }>`
  font-size: 0.875rem;
  min-width: ${(p) => (p.displayRow ? '170px' : '0px')};
  color: #1a1a1a;
  line-height: 1;
  margin-bottom: 4px;
  font-weight: 300;
  display: flex;
  align-items: center;
  flex: 0 0 auto;
`

const InputElement = styled.input<{
  hasError: boolean
  marginLeft: string
  marginRight: string
  maxWidth: string
  password: boolean
  completed: number
  icon?: string
  isValid?: boolean
  id?: any
  placeholder?: string
  borderRadius?: string
}>`
  overflow: hidden;
  position: relative;
  font-weight: normal;
  background: ${(props) => (props.disabled ? '#f1f1f1' : '#fff')};
  border: 1px solid ${(props) => (props.hasError ? '#FC7569' : '#efefef')};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '4px'};
  margin-left: ${(props) => props.marginLeft};
  margin-right: ${(props) => props.marginRight};
  padding: 0 16px;
  padding-left: ${(props) => (!!props.icon ? '48px' : null)} !important;
  font-size: 14px;
  outline: none;
  display: flex;
  flex: 1 1 100%;
  color: #1a1a1a;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  z-index: 1;
  border-radius: 4;
  color: #3f4650;
  height: 50px;
  background-color: #f2f2f2;
  max-width: ${(props) => props.maxWidth};
  + ${IconWrapper} {
    path {
      fill: ${(props) => (props.hasError ? '#FC7569' : '#ccc')};
    }
  }
  &:hover {
    border: ${(props) =>
      props.hasError ? '1px solid #FC7569' : '1px solid #00737f'};
    + ${IconWrapper} {
      path {
        fill: #00737f;
      }
    }
  }
  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-size: 14px;
    font-weight: 200;
    color: #cccccc;
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    font-size: 14px;
    font-weight: 200;
    color: #cccccc;
  }
  &:-ms-input-placeholder {
    /* IE 10+ */
    font-size: 14px;
    font-weight: 200;
    color: #cccccc;
  }
  &:-moz-placeholder {
    /* Firefox 18- */
    font-size: 14px;
    font-weight: 200;
    color: #cccccc;
  }
  &:focus {
    border: ${(props) =>
      props.hasError ? '1px solid #FC7569' : '1px solid rgb(0 106 119)'};
    box-shadow: 0 0 0 3px rgb(0 106 119 / 20%);
    + ${IconWrapper} {
      path {
        fill: rgb(0 106 119);
      }
    }
  }
`

const OuterWrapper = styled.div<{
  maxWidth?: string
  flex?: string
  paddingLeft?: string
  paddingRight?: string
}>`
  display: flex;
  flex: 1;
  max-width: ${(props) => props.maxWidth && props.maxWidth};
  flex: ${(props) => props.flex && props.flex};
  padding-left: ${(props) => props.paddingLeft && props.paddingLeft};
  padding-right: ${(props) => props.paddingRight && props.paddingRight};
`

const InnerWrapper = styled.div<{ hasError?: any; displayRow?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: ${(p: any) => (p.displayRow ? 'row' : 'column')};
  flex: 1 1 100%;
`

export default FormInput
