import config from 'config'
import { post, get } from '../../../utils/axiosUtils'

export const fetchCart = async () => {
  const { payload } = get(`${config.serverUrl}/cart`)

  return await payload
}

export const saveImgPng = async ({ imageData }) => {
  const { payload } = post(`${config.serverUrl}/save-pdf`, { imageData })

  return await payload
}

export const fetchProducts = async () => {
  const { payload } = get(`${config.serverUrl}/products`)

  return await payload
}

export const addProduct = async ({ cartId, lineItemsToAdd }) => {
  const _cartId = cartId.substr(23, 32)
  const keyId = cartId.substr(cartId.indexOf('=') + 1, 32)
  const { payload } = post(`${config.serverUrl}/products/${_cartId}`, {
    lineItemsToAdd,
    keyId
  })

  return await payload
}

export const addPoster = async ({ id, poster }) => {
  const formData = new FormData()
  formData.append('audioFile', poster.audioFile)
  const { payload } = post(`${config.serverUrl}/generate-poster-pdf/${id}`, {
    poster
  })

  return await payload
}

export const fetchPoster = async (fileKey) => {
  const { payload } = get(`${config.serverUrl}/poster/${fileKey}`)

  return await payload
}
