import config from 'config'
import { post, get } from '../../../utils/axiosUtils'

export const generatePosterPdf = async ({ id, audio, poster }) => {
  const formData = new FormData()
  formData.append('file', audio)

  for (const [key, value] of Object.entries(poster)) {
    // @ts-ignore
    formData.append(key, value)
  }

  try {
    const { payload } = post(`${config.serverUrl}/generate-poster-pdf/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })

    return await payload
  } catch (error) {
    throw Error('Etwas ist Schief gelaufen bitte versuchen sie es noch einmal!')
  }
}

/** OLD Function */
// export const getAudioFile = async (id) => {
//   const { payload } = get(`${config.serverUrl}/poster-audio/${id}`, {})
//   return await payload
// }

export const getAudioFile = async (id) => {
  try {
    // Use the Fetch API to get the audio file
    const response = await fetch(`${config.serverUrl}/poster-audio/${id}`);

    // Check if the response is ok (status in the range 200-299)
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    // it's an audio file so we'll get the response as a blob
    const audioBlob = await response.blob();

    // Create a URL for the blob
    const audioUrl = URL.createObjectURL(audioBlob);

    // Return the audio URL
    return audioUrl;
  } catch (error) {
    console.error('Error fetching audio file:', error);
    return null;
  }
}

export const getPosterFile = async (id) => {
  const { payload } = get(`${config.serverUrl}/poster/${id}`, {
    responseType: 'image/jpeg'
  })

  return await payload
}
