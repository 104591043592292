import history from '../history'
import promise from 'redux-promise'

import { routerMiddleware } from 'connected-react-router'
import { middleware as axiosMiddleware } from '../../utils/axiosUtils'

const router = routerMiddleware(history)

let _middlewares = [router, promise, axiosMiddleware]

export const middlewares = [..._middlewares]
