import styled, { keyframes } from "styled-components";
import React, { ReactElement } from "react";

const fadeIn = keyframes`
  0% { opacity: 0; transform: translateY(-10px); }
  100% { opacity: 1; transform: translateY(0); }
`;

const Transition = styled.div`
  display: flex;
  flex-direction: column;
  animation: ${fadeIn} 0.6s;
  width: 100%;
  height: 100%;
`;

const Wrapper: React.FC<{ children: ReactElement }> = ({ children }) => {
  return <Transition>{children}</Transition>;
};

export default Wrapper;
