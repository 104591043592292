import { styled } from 'core'

import Container from '../Container/Container'

const GridColumn = styled(Container)`
  gap: ${(props) => props.gap || ''};
  display: ${(props) => props.display || 'flex'};
  flex: ${(props) => props.flex || '0 1 auto'};
  width: ${(props) => props.width || '100%'};
  flex-direction: ${(props) => props.flexDirection || 'column'};
`

export default GridColumn
