import { FieldState } from 'final-form'
import isEmail from 'validator/lib/isEmail'
import isPostalCode from 'validator/lib/isPostalCode'

// copied from final-form and modified
type FieldValidator = (
  value: string,
  allValues: object,
  meta?: FieldState<string>
) => any | Promise<any>

type ComposeValidators = (...validatorFuncs: FieldValidator[]) => FieldValidator

export const composeValidators: ComposeValidators = (...validationFuncs) => (
  val,
  values
) => {
  const functionResponses = validationFuncs.map((func) => func(val, values))

  return functionResponses.find((result) => !!result)
}

export const emailValidator: FieldValidator = (val, values) => {
  if (!val) {
    return
  }

  if (!isEmail(val.toLowerCase())) {
    return 'Keine valide E-Mail'
  }
}

export const postalCodeValidator: FieldValidator = (
  val: string | number,
  values
) => {
  if (!val) {
    return
  }

  let value = typeof val === 'string' ? val : val.toString()

  if (!isPostalCode(value, 'DE')) {
    return 'Keine valide PLZ'
  }
}

export const phoneValidator: FieldValidator = (val, values) => {
  const isPhoneNumber = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/

  if (!val) {
    return
  }

  if (!isPhoneNumber.test(val)) {
    return 'Keine valide Telefonnummer'
  }
}

export const isRequired: (errorMessage: string) => FieldValidator = (
  errorMessage
) => (val) => {
  if (!val) {
    return errorMessage
  }

  return undefined
}

export const emailRequired = isRequired('E-Mail wird benötigt')
export const phoneRequired = isRequired('Telefonnummer wird benötigt')

export const passwordValidator: FieldValidator = (val) => {
  if (!val) {
    return 'Passwort wird benötigt'
  }

  switch (false) {
    case /[a-z]/.test(val):
    case /[A-Z]/.test(val):
    case /[\!\@\#\$\%\^\&\*\+\_\-\~]/.test(val):
    case /[0-9]/.test(val):
    case val.length >= 8:
      return ''
    default:
      return undefined
  }
}

export const passswordRequired: FieldValidator = (val) => {
  if (!val) {
    return 'Passwort wird benötigt'
  }
}

export const passwordConfirmValidator: FieldValidator = (val, values) => {
  if (val !== (values as any).password) {
    return 'Passwörter stimmen nicht über ein'
  }
}

export const confirmationCodeValidator: FieldValidator = (val) => {
  if (!val) {
    return 'Code wird benötigt'
  }
}
