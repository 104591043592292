import axios from 'axios'

export const middleware = () => (next) => (action) => {
  const { cancel, meta = {}, type, ...rest } = action

  return cancel
    ? next({
        ...rest,
        type,
        meta: {
          ...meta,
          cancel
        }
      })
    : next(action)
}

const fn = (method) => (url, data = undefined, axiosConfig = {}) => {
  const { cancel, token } = axios.CancelToken.source()

  const payload = axios({
    ...axiosConfig,
    url,
    data,
    method,
    cancelToken: token
  }).then((res) => res.data)

  return { payload, cancel }
}

const get = fn('get')
const del = fn('delete')
const head = fn('head')
const options = fn('options')
const patch = fn('patch')
const post = fn('post')
const put = fn('put')

export default {
  get,
  del,
  head,
  options,
  patch,
  post,
  put
}
export { get, del, head, options, patch, post, put }
