import { FC } from 'react'
import { React, css, styled } from 'core'

const Checkbox: FC<CheckboxProps> = ({ children, checked, values = [false, true], onChange, disabled = false, style, ...props }) => {

  const handleOnChange = (event) => {
    const value = event.target.checked ? 0 : 1
    const realValue = values[value]
    onChange && onChange(realValue)
  }

  const realValue = values.indexOf(checked)

  return (
    <CheckboxWrapper withLabel={!!children} style={style}>
      <PureCheckbox disabled={disabled} type='checkbox' checked={realValue} onClick={handleOnChange} {...props} />
      {children && <CheckboxLabel>{children}</CheckboxLabel>}
      <CheckboxIndicator />
    </CheckboxWrapper>
  )
}

export type CheckboxProps = {
  id?: String | number
  type?: string
  children?: any
  checked: string | boolean | number
  values?: [string | boolean |number, string | boolean | number]
  onClick?: (event: React.MouseEvent<HTMLInputElement>) => void
  onChange?: (value: string | boolean | number) => void
  style?: any
  disabled?: boolean
}

export default Checkbox

const CheckboxLabel = styled.label`
  padding-left: 24px;
  font-size: 14px;
`

const CheckboxIndicator = styled.div`
  position: absolute;
  top: 0px;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 6px;
  background: white;
  border: 1px solid #00737F;
  &:after {
    content: '';
    position: absolute;
    display: none;
    left: 7px;
    top: 3px;
    width: 3px;
    height: 8px;
    border-style: solid;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
`

const PureCheckbox = styled.input<any>`
  position: absolute;
  width: 18px;
  height: 18px;
  z-index: 10;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  opacity: 0;

  ${props => props.checked && css`
    & ~ ${CheckboxIndicator} {
      background: #00737F;
      border: 1px solid #00737F;
    }

    & ~ ${CheckboxIndicator}:after {
      display: block;
      border-color: white;
    }
  `}

  &:disabled ~ ${CheckboxIndicator} {
    background: #ccc;
    opacity: 0.6;
    pointer-events: none;
    border: 1px solid #7b7b7b;
  }

  &:disabled ~ ${CheckboxIndicator}:after {
    border-color: #7b7b7b;
  }
`

const CheckboxWrapper = styled.label<{ withLabel?: boolean }>`
  display: block;
  position: relative;
  height: 20px;
  width: ${props => (props.withLabel ? '100%' : '20px')};
  cursor: pointer;
  font-size: 18px;
  margin: 6px 0px;
`
