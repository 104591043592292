import styled from 'styled-components'
import React, { HTMLProps } from 'react'

const Radio: React.FC<RadioProps> = ({ label }) => {
  return (
    <Wrapper>
      <label className='container'>
        {label}
        <input type='checkbox' />
        <span className='checkmark'></span>
      </label>
    </Wrapper>
  )
}

interface RadioProps extends HTMLProps<HTMLInputElement> {}

export default Radio

const Label = styled.div`
  .label--radio {
    position: relative;

    margin: 0.5rem;

    font-family: Arial, sans-serif;
    line-height: 135%;

    cursor: pointer;
  }
`

const Wrapper = styled.div`
  /* Customize the label (the container) */
  .container {
    display: block;
    position: relative;
    padding-left: 35px;
    color: #1a1a1a;
    cursor: pointer;
    font-size: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default radio button */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    transition: all 0.3s ease-in-out;
  }

  /* Create a custom radio button */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: #f2f2f2;
    transition: all 0.3s ease-in-out;
    /* border: 2px solid #eee; */
    border-radius: 50%;
  }

  /* On mouse-over, add a grey background color */
  /* .container:hover input ~ .checkmark {
    background-color: #00737f;
  } */

  /* When the radio button is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: #;
  }

  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the indicator (dot/circle) when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the indicator (dot/circle) */
  .container .checkmark:after {
    transition: all 0.3s ease-in-out;
    top: 3px;
    left: 3px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #00737f;
  }
`
