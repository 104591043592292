import { update } from 'core'
import { PosterState } from './types'
import { createReducer } from '@reduxjs/toolkit'
import { updatePoster, setIsSelectVariants, setPosterLoading, getPosterFile, setCheckoutLoading } from './actions'

const initialState: PosterState = {
  posterFromServer: {
    posterImg: undefined,
    posterAudio: undefined
  },
  poster: {
    headerTitle: '',
    bgColor: 'black',
    aquarellEffect: 'https://cdn.shopify.com/s/files/1/0561/0589/4075/files/bdabf80581cf9c9502e2080da312d288.png?v=1645460794',
    waveColor: 'white',
    subTitle: 'The night of my dreams was the beginning of my reality',
    mainTitle: 'BEAUTIFUL DREAM',
    showQRCode: true,
    cursiveFont: false,
    audioMetadata: undefined,
    showBorder: true,
    selectedStyle: 'black/white',
    activeStyle: 'Classic',
    audioFile: undefined,
    frame: undefined
  },
  isSelectVariants: false,
  isLoading: false,
  checkOutIsLoading: false
}

export default createReducer(initialState, (builder) => {
  builder.addCase(updatePoster, (state, action) => {
    return update(state, {
      poster: {
        $set: action.payload.poster
      }
    })
  })

  builder.addCase(setCheckoutLoading, (state, action) => {
    return update(state, {
      checkOutIsLoading: {
        $set: action.payload.isLoading
      }
    })
  })

  builder.addCase(setIsSelectVariants, (state, action) => {
    return update(state, {
      isSelectVariants: {
        $set: action.payload.isSelectVariants
      }
    })
  })

  builder.addCase(setPosterLoading, (state, action) => {
    return update(state, {
      isLoading: {
        $set: action.payload.isLoading
      }
    })
  })
})
