import { Configuration } from "../../environments/types";

let config: Configuration;

switch (process.env.ENV) {
  case "development":
    config = require("../../environments/development").default;
    break;
  case "test":
    config = require("../../environments/test").default;
    break;
  case "production":
    config = require("../../environments/production").default;
    break;
  default:
    config = require("../../environments/defaults").default;
}

export default config;
