/* eslint-disable max-lines-per-function */
import { createAction } from '@reduxjs/toolkit';

export enum ActionStates {
    On = "on",
    Request = "request",
    Success = "success",
    Failed = "failed"
}

type ExtendedR<R> = R & { onError?: (e: Error) => void }


export const createAsyncAction = <R, S, F>(originType: string) => {
    const _on = createAction<R>(`${originType.toUpperCase()}/${ActionStates.On.toUpperCase()}`);
    const _request = createAction<ExtendedR<R>>(`${originType.toUpperCase()}/${ActionStates.Request.toUpperCase()}`);
    const _success = createAction<S>(`${originType.toUpperCase()}/${ActionStates.Success.toUpperCase()}`);
    const _failed = createAction<F>(`${originType.toUpperCase()}/${ActionStates.Failed.toUpperCase()}`);

    const on = (t?: R) => {
        const { type, payload } = _on(t)

        return {
            type,
            payload: payload as R,
            meta: {
                async: true,
                state: ActionStates.On,
                type: originType
            }
        }
    }
    on.toString = () => _on.toString()
    on.type = _on.type
    on.match = _on.match

    const request = (t?: ExtendedR<R>) => {
        const { type, payload } = _request(t)

        return {
            type,
            payload: payload as ExtendedR<R>,
            meta: {
                async: true,
                state: ActionStates.Request,
                type: originType
            }
        }
    }
    request.toString = () => _request.toString()
    request.type = _request.type
    request.match = _request.match

    const success = (t?: S) => {
        const { type, payload } = _success(t)

        return {
            type,
            payload: payload as S,
            meta: {
                async: true,
                state: ActionStates.Success,
                type: originType
            }
        }
    }
    success.toString = _success.toString
    success.type = _success.type
    success.match = _success.match

    const failed = (t?: F) => {
        const { type, payload } = _failed(t)

        return {
            type,
            payload: payload as F,
            meta: {
                async: true,
                state: ActionStates.Failed,
                type: originType
            }
        }
    }
    failed.toString = _failed.toString
    failed.type = _failed.type
    failed.match = _failed.match
    const action = {
        on,
        request,
        success,
        failed,
    }

    action.toString = () => originType
    //@ts-ignore
    action.type = originType

    return action
};
