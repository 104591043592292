import { React } from 'core'

import { ToastOptions } from 'react-toastify'
import { toast } from '../../adapters/react-toastify'
import { default as ToastMessage } from '../../components/Toast/ToastMessage'

/*
 * Adapter for react-toastify
 * https://github.com/fkhadra/react-toastify
 **/



export const ToastService = {
  notify (message: string, options?: ToastOptions) {
    return toast(<ToastMessage message={message} />, {
      position: ToastService.Position.BOTTOM_RIGHT,
      ...options
    })
  },
  info (message: string, options?: ToastOptions) {
    return ToastService.notify(message, {
      type: ToastService.Type.INFO,
      ...options
    })
  },
  error (message: string, options?: ToastOptions) {
    return ToastService.notify(message, {
      type: ToastService.Type.ERROR,
      ...options
    })
  },
  warning (message: string, options?: ToastOptions) {
    return ToastService.notify(message, {
      type: ToastService.Type.WARNING,
      ...options
    })
  },
  success (message: string, options?: ToastOptions) {
    return ToastService.notify(message, {
      type: ToastService.Type.SUCCESS,
      ...options
    })
  },
  //@ts-ignore
  custom (CustomComponent, props, options?: ToastOptions) {
    return toast(<CustomComponent {...props} />, {
      position: ToastService.Position.BOTTOM_RIGHT,
      ...options
    })
  },
  Position: {
    TOP_LEFT: toast.POSITION.TOP_LEFT,
    TOP_RIGHT: toast.POSITION.TOP_RIGHT,
    TOP_CENTER: toast.POSITION.TOP_CENTER,
    BOTTOM_LEFT: toast.POSITION.BOTTOM_LEFT,
    BOTTOM_RIGHT: toast.POSITION.BOTTOM_RIGHT,
    BOTTOM_CENTER: toast.POSITION.BOTTOM_CENTER
  },
  Type: {
    INFO: toast.TYPE.INFO,
    ERROR: toast.TYPE.ERROR,
    WARNING: toast.TYPE.WARNING,
    SUCCESS: toast.TYPE.SUCCESS
  }
}
