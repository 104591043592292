import { React, styled, useState } from "core";
import Icon from "../Icon/Icon";

const Search = ({ value, onChange }) => {
  return (
    <SearchWrapper>
      <SearchInput onChange={({ target }) => onChange(target.value)} value={value} placeholder="Suche..." />
      <SearchIconWrapper>
        <Icon icon="search" size="25" />
      </SearchIconWrapper>
    </SearchWrapper>
  );
};

export default Search;

const SearchWrapper = styled.div`
  display: flex;
  position: relative;
`;

const SearchInput = styled.input`
  width: 280px;
  height: 45px;
  background: #f2f2f2;
  border: 1px solid #efefef;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  outline: none;
  padding-right: 45px;
  color: #1a1a1a;
  transition: all 0.3s ease-in-out;

  &:hover {
    border: 1px solid #00737f;
    path {
      fill: #00737f;
    }
  }

  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-size: 14px;
    font-weight: 200;
    color: #cccccc;
  }
  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-size: 14px;
    font-weight: 200;
    color: #cccccc;
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    font-size: 14px;
    font-weight: 200;
    color: #cccccc;
  }
  &:-ms-input-placeholder {
    /* IE 10+ */
    font-size: 14px;
    font-weight: 200;
    color: #cccccc;
  }
  &:-moz-placeholder {
    /* Firefox 18- */
    font-size: 14px;
    font-weight: 200;
    color: #cccccc;
  }
  &:focus {
    border: 1px solid rgb(0 115 127 / 57%);
    box-shadow: 0 0 0 3px rgb(0 106 119 / 20%);
    transition: 0.3s;
  }
`;

const SearchIconWrapper = styled.div`
  position: absolute;
  height: 45px;
  width: 45px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 4px 4px 0;
  top: 0;
  right: 0;
  border-left: 0;

  path {
    fill: #dadada;
  }
`;
