import { keyframes } from './keyframes'

export const animations = {
  show: `
    animation: show 0.7s ease forwards;
    ${keyframes.show};
    
  `,
  elliptic: `
    ${keyframes.elliptic};
    animation: elliptic 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  `
}
