import { React, styled, memo, css } from 'core'
import { FC, HTMLProps } from 'react'

import ErrorMessage from '../ErrorMessage/ErrorMessage'
import GridRow from '../GridRow/GridRow'
import GridColumn from '../GridColumn/GridColumn'

const Textarea: FC<HTMLProps<HTMLInputElement> & TextareaProps> = memo(
  ({
    component,
    placeholder,
    name,
    type,
    onChange,
    value,
    error,
    hasError,
    valueLimit,
    valueMinimum,
    disabled,
    isTouched,
    ...props
  }) => {
    if (value) {
      hasError = undefined
    }

    const showError: boolean = (error && isTouched) || !!hasError
    const counter = value.length
    return (
      <Wrapper>
        <StyledTextArea
          component={component}
          placeholder={placeholder}
          disabled={disabled}
          name={name}
          type={type}
          onChange={onChange}
          hasError={showError}
          value={value}
          {...props}
        />
        <GridRow>
          <GridColumn flex='0 1 auto'>
            {showError && <ErrorMessage>{error}</ErrorMessage>}
          </GridColumn>
          {valueLimit && (
            <GridColumn
              padding='2px'
              paddingLeft='8px'
              flexDirection='row'
              width='auto'
              flex='0 0 auto'
            >
              <Count
                limit={valueLimit}
                minimum={valueMinimum}
                counter={counter}
              >
                {counter}
                <span>&nbsp;</span>
              </Count>
              <Limit>
                /<span>&nbsp;</span>
                {valueMinimum || 0}-{valueLimit}
              </Limit>
            </GridColumn>
          )}
        </GridRow>
      </Wrapper>
    )
  }
)

export type TextareaProps = {
  component?: JSX.Element
  type?: string
  placeholder?: string
  disabled?: boolean
  error?: string | undefined
  hasError?: boolean
  value: string
  name?: string
  onChange?: Function
  label?: string
  required?: boolean
  valueLimit?: number | string
  valueMinimum?: number | string
  showIcon?: boolean
  iconType?: string
  style?: Record<string, string | number>
  isTouched?: boolean
}

export default Textarea

const Count = styled.span<any>`
  font-size: 12px;
  color: ${(props) => (props.counter > props.limit ? '#fc7569' : '#848B91')};
`

const Limit = styled.span`
  font-size: 12px;
  color: #848b91;
`

const Wrapper = styled.div`
  position: relative;
`

const StyledTextArea = styled.textarea<any>`
  width: 100%;
  height: 175px;
  border: 1px solid #efefef;
  font-size: 14px;
  padding: 16px;
  border-radius: 4px;
  color: #3f4650;
  font-family: 'Heebo';
  background-color: #f2f2f2;
  transition: all 0.3s ease-in-out;

  ${(props) =>
    props.hasError &&
    css`
      border-color: #fc7569;
    `}

  &:hover {
    border: 1px solid #00737f;
  }
  &:focus {
    outline: none;
    border: 1px solid rgb(0 106 119);
    box-shadow: 0 0 0 3px rgb(0 106 119 / 20%);
  }
  &:focus-within {
    outline: none;
  }
  &::placeholder {
    color: #d6d6d6;
  }
`
