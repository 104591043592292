import history from '../history'
import Poster from './Poster/reducer'
import Cart from './Cart/reducer'

import { combineReducers } from 'core'
import { connectRouter } from 'connected-react-router'
import { LocationState } from 'history'
import { LoadingReducer as Loading } from '../../packages/loading'
import { ModalReducer as Modal } from '../../packages/modal'

import { ModalState } from '../../packages/modal/service'
import { LoadingState } from '../../packages/loading/service'
import { PosterState } from './Poster'
import { CartState } from './Cart'

export type RootState = {
  Poster: PosterState
  Modal: ModalState
  Cart: CartState
  router: LocationState
  Loading: LoadingState
}

export default combineReducers<RootState>({
  Loading,
  Cart,
  Poster,
  router: connectRouter(history),
  Modal
})
