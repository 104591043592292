import React, { FC, memo } from 'react'
import styled from 'styled-components'

import { getIcon } from './Icon.utils'

const Icon: FC<IconProps> = ({ icon, inline, size, color, source, ...props }) => {
  const __html = icon ? getIcon(icon) : source
  return (
    <IconSource
      inline={inline}
      size={size}
      color={color}
      dangerouslySetInnerHTML={{ __html }}
      {...props}
    />
  )
}

const IconSource = styled.div<any>`
  background-size: cover;
  display: ${props => props.inline ? 'inline' : 'flex'};
  height: ${props => props.size}px;
  width: ${props => props.size}px;

  path {
    fill: ${props => props.color};
  }
`

export type IconProps = {
  icon?: string,
  inline?: boolean,
  size?: number | string,
  color?: string,
  source?: string,
  style?: any,
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
}

export default memo(Icon)
