import { React, Select as ReactSelect, styled } from 'core'
import { FC, HTMLProps, useEffect } from 'react'

type SelectProps = {
  options: Array<Record<string, any>>
}

const Select: FC<HTMLProps<HTMLInputElement> & SelectProps & any> = ({
  displayRow,
  label,
  options,
  menuPortalTarget = false,
  allowSelectAll = false,
  ...props
}) => {
  useEffect(() => {
    if (props.isDisabled) {
      props.onChange([])
    }
  }, [props.isDisabled])

  return (
    <SelectWrapper disabled={props.isDisabled} displayRow={displayRow}>
      <Label displayRow={displayRow}>{label}</Label>
      <InnerWrapper>
        <CustomSelect
          styles={SelectStyles}
          menuPortalTarget={menuPortalTarget && document.body}
          menuPlacement='top'
          className='select-component'
          placeholder='Auswählen...'
          noOptionsMessage={() => 'keine Optionen'}
          loadingMessage={() => 'laden...'}
          classNamePrefix='custom-select'
          options={options}
          {...props}
        />
      </InnerWrapper>
    </SelectWrapper>
  )
}

export default Select

const Label = styled.label<{ displayRow?: boolean }>`
  font-size: 0.875rem;
  color: #1a1a1a;
  line-height: 1;
  margin-bottom: 4px;
  font-weight: 300;
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  min-width: ${(p) => (p.displayRow ? '170px' : '0px')};
`

const SelectWrapper = styled.div<{ displayRow?: boolean }>`
  display: flex;
  flex: 1;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  padding: 10px 0;
  flex-direction: ${(p) => (p.displayRow ? 'row' : 'column')};
  .css-b62m3t-container {
    display: flex !important;
    flex: 1 !important;
    color: #1a1a1a;
    &:focus {
      box-shadow: 0 0 0 3px rgb(0 106 119 / 20%);
    }
  }
`

const InnerWrapper = styled.div`
  padding: 8px 0px;
  display: flex;
  flex: 1 1 100%;
`

const SelectStyles = {
  container: (base) => ({
    ...base,
    display: 'flex',
    flex: '1'
  }),
  // control: (base) => ({
  //   ...base,
  //   "&:hover": {
  //     borderColor: "rgb(255, 204, 0 / 50%)",
  //   },
  //   border: "#e1e1e1",
  //   boxShadow: "rgb(255, 204, 0 / 50%)",
  // }),
  option: (styles, { isFocused, isSelected }) => {
    return {
      ...styles,
      margin: 0,
      '&:hover': {
        backgroundColor: isSelected ? 'rgb(255, 204, 0)' : '#ffcc0038'
      },
      backgroundColor:
        !isSelected && isFocused
          ? '#ffcc0038'
          : isSelected
          ? 'rgb(255, 204, 0)'
          : null,
      color: 'black',
      cursor: 'pointer'
    }
  },
  menu: (styles) => ({
    ...styles,
    marginBottom: 5
  }),
  multiValue: (styles) => {
    return {
      ...styles,
      height: '30px',
      fontSize: '14px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  },
  loadingIndicator: (styles) => {
    return {
      ...styles,
      scale: '0.5'
    }
  }
}

const CustomSelect = styled(ReactSelect)<{
  icon: boolean
  isMulti: boolean
  inToolbar?: boolean
}>`
  /* &:hover {
    .custom-select__control {
      border: 1px solid #ccc;
    } */
  &:focus {
    .custom-select__control {
      border: 1px solid #00737f;
      box-shadow: 0 0 0 3px rgb(0 106 119 / 20%);
    }
  }
  &:active {
    .custom-select__control {
      border: 1px solid #00737f;
    }
  }
  .custom-select__control {
    min-height: 50px;
    background-color: #f2f2f2;
    display: flex;
    flex: 1;
    border: 1px solid #efefef;
    font-weight: 400;
    color: #1a1a1a;
    font-size: 14px;
    box-shadow: 0 0 0 3px rgb(0 106 119 / 0%);
    border-radius: 4;
    transition: all 0.3s ease-in-out;
    &:hover {
      border: 1px solid #00737f;
    }
  }
  .custom-select__menu {
    z-index: 3;
    padding: 0rem;
    border: 1px solid #efefef;
    background: #fff;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }
  .custom-select__menu-list {
    border-radius: 4px;
    padding: 0rem;
    background: #fff;
    color: #1a1a1a;
  }
  .custom-select__option {
    padding: 16px;
    border-bottom: 1px solid #efefef;
    font-size: 14px;
    background: #fff;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    color: #1a1a1a;
    &:hover {
      background: #fc0;
      color: #1a1a1a;
    }
  }
  .custom-select__indicator-separator {
    background: transparent;
  }
  .custom-select__dropdown-indicator {
    path {
      background: #7b7b7b;
    }
  }
  .custom-select__value-container--has-value {
    color: #fc0;
    padding-left: ${(props) => (props.icon ? '48px' : null)};
    .optionLabelIcon {
      display: none;
    }
    .optionLabelText {
      padding: 0px;
    }
  }
  .custom-select__multi {
    .optionLabelText {
      color: #ccc;
    }
  }
  .custom-select__input-container {
    color: #1a1a1a;
    padding-left: ${(props) => (props.icon ? '40px' : null)};
    padding-left: ${(props) => (props.isMulti ? '8px' : null)};
  }
  .custom-select__multi-value__remove {
    cursor: pointer;
    color: #1a1a1a;
    transition: all 0.3s ease-in-out;
    border-radius: 0px 4px 4px 0px;
    height: 30px;
    &:hover {
      background-color: #e7b900;
      color: #1a1a1a;
    }
  }
  .custom-select__single-value {
    color: #1a1a1a;
  }
  .custom-select__placeholder {
    padding-left: ${(props) => (props.icon ? '40px' : null)};
    font-size: 14px;
    padding-left: 8px;
    font-weight: 400;
    color: #1a1a1a;
  }
  .custom-select__multi-value {
    background: #fc0;
    &:hover {
      background: #ffd21c;
    }
    transition: all 0.3s ease-in-out;
    border-radius: 4px;
  }
  .custom-select__multi-value__label {
  }
`
