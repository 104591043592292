import PosterRoutes from './Modules/Poster/routes'

export type Route = {
  path: string
  name: string
  exact: boolean
  render: any
  noAuthenticationRequired?: boolean
}

export default [
  ...PosterRoutes
]
