import { React, useMemo, styled } from "core";
import { matchPath, useHistory, useRouteMatch } from "router";
import { motion } from "framer-motion";

import Icon from "../Icon/Icon";

const MenuItemExt = ({ icon, text, to }) => {
  const match = useRouteMatch();
  const history = useHistory();

  const isActive = useMemo(() => {
    return matchPath(match.url, {
      path: to,
      exact: false,
      strict: false,
    });
  }, [to, match.url]);

  return (
    <NavItemWrapper
      active={isActive}
      onClick={() => {
        history.push(to);
      }}
    >
      <Icon size={25} icon={icon} color={isActive ? "#00737F" : "#9A9A9A"} />
      <Label active={isActive}>{text}</Label>
      {isActive && <ActiveBar layoutId="underline" />}
    </NavItemWrapper>
  );
};

export default MenuItemExt;

const Label = styled.p`
  color: ${(props) => (props.active ? "#00737F" : "#9A9A9A")};
  padding: 0 10px;
  padding-left: 16px;
  transition: all 0.3s ease-in-out;
  font-weight: 500;
`;

const NavItemWrapper = styled.div`
  display: flex;
  margin: 16px 16px;
  padding: 8px 8px;
  color: #667;
  user-select: none;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  position: relative;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;

  &:hover {
    background: #f9f9f9;
    ${Label} {
      color: #666666;
      path {
        fill: #00737f !important;
      }
    }
    ${Icon} {
      path {
        transition: all 0.3s ease-in-out;
        fill: #00737f !important;
      }
    }
  }
`;

const ActiveBar = styled(motion.div)`
  position: absolute;
  left: -16px;
  background: #00737f;
  width: 1px;
  height: 35px;
  border: 1px solid transparent;
  border-radius: 0 100px 100px 0;
`;
